@font-face {
  font-family: SharpSans;
  font-weight: 500;
  src: url('./fonts/Sharp Sans No1-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: PitchMedium;
  src: url('./fonts/pitch-web-medium.woff') format('woff'),
    url('./fonts/pitch-web-medium.eot') format('eot'),
    url('./fonts/pitch-web-medium.woff2') format('woff2');
}


* {
  margin: 0;
  padding: 0;
}

body {
  font-size: 14px;
  font-family: PitchMedium, monospace;
}

h1, button.login-btn {
  font-family: SharpSans, monospace;
  font-size: 2em;
  color: black;
  font-weight: 500;
}

h2 {
  font-family: SharpSans, monospace;
  font-weight: 500;
  font-size: 2.6em;
  padding-bottom: 10px;
}

/* 
  .main {
    padding: 6vw;
    display: flex;
    flex-direction: column;
  } */

  .section {
    display:  flex;
    flex-direction: column;
  }

  .two-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }

  .centered {
    max-width: 1000px;
    margin: 0 auto; 
    padding: 6vh 6vw;
  }

  .nuclear {
    padding-bottom: 6vh;
  }

  .login {
    display: flex;
    padding-bottom: 6vh;
  }

  button.login-btn, button.logout-btn {
    background: none;
    color: black;
    border: none;
    padding: 0 0 0 12px;
    cursor: pointer;
    outline: inherit;
  }